import React from 'react';
import clsx from 'clsx';
import { Field } from 'formik';
import * as styles from './TextField.module.css';

export default function TextField({ label, id, name, values, touched, errors, required, type, className }) {
  type = type ?? 'text';
  id = id ?? name;
  const value = values[name] ?? '';
  touched = touched[name] ?? false;
  const error = errors[name] ?? undefined;
  return (
    <div className={clsx(className)}>
      <label className={styles.label} htmlFor={id}>{label}{required && (<abbr title="Pflichtfeld">*</abbr>)}</label>
      <Field
        type={type}
        id={id}
        name={name}
        value={value}
        required={required ?? false}
        className={clsx(styles.formControl, touched && error && styles.invalidField)}
      />
      {touched && error && (<div className={styles.errorMessage}>{error}</div>)}
    </div>
  )
}
