import React from 'react';
import clsx from 'clsx';
import { Field } from 'formik';
import * as styles from './SelectField.module.css';

export default function SelectField({ label, id, name, options, values, touched, errors, required, type, className }) {
  type = type ?? 'text';
  id = id ?? name;
  (typeof options === 'object') || (options = {});
  const value = values[name] ?? '';
  touched = touched[name] ?? false;
  const error = errors[name] ?? undefined;

  return (
    <div className={clsx(className)}>
      <label className={styles.label} htmlFor={id}>{label}{required && (<abbr title="Pflichtfeld">*</abbr>)}</label>
      <Field
        as="select"
        id={id}
        name={name}
        value={value}
        required={required ?? false}
        className={clsx(styles.formControl, touched && error && styles.invalidField)}
      >
        <option key="empty" value=""></option>
        {Object.keys(options).map(key => (
          <option key={key} value={key}>{options[key]}</option>
        ))}
      </Field>
      {touched && error && (<div className={styles.errorMessage}>{error}</div>)}
    </div>
  )
}
