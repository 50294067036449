// Jeder Block muss zweimal hinzugefügt werden.
// 1. Block importieren:
// Theme-Blöcke
import BlockNotFound from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/BlockNotFound"
import HeroBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/HeroBlock"
import TextBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TextBlock"
import ImageBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/ImageBlock"
import QuoteBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/QuoteBlock"
import VideoBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/VideoBlock"
import TextVideoBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TextVideoBlock"
import FeaturesBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/FeaturesBlock"
import TeamBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TeamBlock"
import CallToActionBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/CallToActionBlock"
import StatsBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/StatsBlock"
import EmbedBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/EmbedBlock"
import GalleryBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/GalleryBlock"
import FolderBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/FolderBlock"
import DownloadsBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/DownloadsBlock"
import LogoGridBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/LogoGridBlock"
import TableBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TableBlock"
import FaqBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/FaqBlock"
import CardsBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/CardsBlock"
import TextImageBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/TextImageBlock"
import RichlinkBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/RichlinkBlock"
import ContentNavBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/ContentNavBlock"
import SubNavBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/SubNavBlock"
import InfoboxBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/InfoboxBlock"
import ArticleListBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/ArticleListBlock"

// Kunden-spezifische Blöcke
// import CountdownBlock from 'gatsby-theme-yum-components/src/components/blocks/CountdownBlock';
import HeadlineBlock from "../../../../components/blocks/HeadlineBlock"
import PricingBlock from "../../../../components/blocks/PricingBlock"
import ThreeTwoOneBlock from "../../../../components/blocks/ThreeTwoOneBlock"
import RatingsBlock from "../../../../components/blocks/RatingsBlock"
import DoubleImageBlock from "../../../../components/blocks/DoubleImageBlock"
import SectorTeaserBlock from "../../../../components/blocks/SectorTeaserBlock"
import DownloadEntriesBlock from "../../../../components/blocks/DownloadEntriesBlock"
import InfoGraphicBlock from "../../../../components/blocks/InfoGraphicBlock"
import RelatedNewsBlock from "../../../../components/blocks/RelatedNewsBlock"
import LogoScrollerBlock from "../../../../components/blocks/LogoScrollerBlock"
import LoginOptionsBlock from "../../../../components/blocks/LoginOptionsBlock"
import AnchorBlock from "../../../../components/blocks/AnchorBlock"
import ButtonsBlock from "../../../../components/blocks/ButtonsBlock"
import BenefitsBlock from "../../../../components/blocks/BenefitsBlock"
import ImageHoverTextBlock from "../../../../components/blocks/ImageHoverTextBlock"

import OrderFormBlock from "../../../../components/blocks/OrderFormBlock"

// 2. CMS-Block auf React-Block mappen:
const BlocksList = {
  hero_block: HeroBlock,
  text_block: TextBlock,
  image_block: ImageBlock,
  quote_block: QuoteBlock,
  video_block: VideoBlock,
  text_video_block: TextVideoBlock,
  features_block: FeaturesBlock,
  team_block: TeamBlock,
  cta_block: CallToActionBlock,
  stats_block: StatsBlock,
  embed_block: EmbedBlock,
  gallery_block: GalleryBlock,
  folder_block: FolderBlock,
  downloads_block: DownloadsBlock,
  download_entries_block: DownloadEntriesBlock,
  logo_grid_block: LogoGridBlock,
  table_block: TableBlock,
  faq_block: FaqBlock,
  cards_block: CardsBlock,
  text_image_block: TextImageBlock,
  richlink_block: RichlinkBlock,
  content_nav_block: ContentNavBlock,
  sub_nav_block: SubNavBlock,
  infobox_block: InfoboxBlock,
  article_list_block: ArticleListBlock,

  headline_block: HeadlineBlock,
  pricing_block: PricingBlock,
  three_two_one_block: ThreeTwoOneBlock,
  ratings_block: RatingsBlock,
  double_image_block: DoubleImageBlock,
  sector_teaser_block: SectorTeaserBlock,

  info_graphic_block: InfoGraphicBlock,
  related_news_block: RelatedNewsBlock,
  logo_scroller_block: LogoScrollerBlock,
  login_options_block: LoginOptionsBlock,
  anchor_block: AnchorBlock,
  buttons_block: ButtonsBlock,
  benefits_block: BenefitsBlock,
  image_hover_text_block: ImageHoverTextBlock,

  order_form_block: OrderFormBlock,
}

// Diese Logik kann unverändert bleiben
const Blocks = (type) => {
  if (typeof BlocksList[type] === "undefined") {
    return BlockNotFound
  }
  return BlocksList[type]
}

export default Blocks
