// extracted by mini-css-extract-plugin
export var a = "OrderFormBlock-module--a--BQs1w";
export var alertDanger = "OrderFormBlock-module--alert-danger--s1YMX";
export var btn = "OrderFormBlock-module--btn--0v9mN";
export var btnPrimary = "OrderFormBlock-module--btn-primary--PSkWv";
export var error = "OrderFormBlock-module--error--dQRNr";
export var errorMessage = "OrderFormBlock-module--errorMessage--X8fF4";
export var form = "OrderFormBlock-module--form--ZkCho";
export var formContainer = "OrderFormBlock-module--formContainer--03mK-";
export var grey = "OrderFormBlock-module--grey--Td5sw";
export var h1 = "OrderFormBlock-module--h1--uP-3j";
export var h2 = "OrderFormBlock-module--h2--CAnO8";
export var h3 = "OrderFormBlock-module--h3--aW3Q7";
export var h4 = "OrderFormBlock-module--h4--mqtox";
export var h5 = "OrderFormBlock-module--h5--Xl5n+";
export var h6 = "OrderFormBlock-module--h6--Bs5kU";
export var li = "OrderFormBlock-module--li--88wx2";
export var main = "OrderFormBlock-module--main--GW+h5";
export var modulPrice = "OrderFormBlock-module--modul-price--5cGkt";
export var modulPriceBlock = "OrderFormBlock-module--modul-price-block--T8TIT";
export var modulPriceBlockInner = "OrderFormBlock-module--modul-price-block-inner--60JlT";
export var p = "OrderFormBlock-module--p--Srh9s";
export var row = "OrderFormBlock-module--row--ztbIO";
export var small = "OrderFormBlock-module--small--FyQel";
export var ul = "OrderFormBlock-module--ul--3enMY";